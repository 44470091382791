import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import styles from "./manageUsers.module.css";

const cookies = new Cookies();

function ManageUsers(props) {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Track the total number of pages
  const [loading, setLoading] = useState(false); // For loading state

  const token = cookies.get("TOKEN");

  const fetchUsers = async (page = 1) => {
    setLoading(true); // Show loading while fetching data
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-users?page=${page}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUsers(response.data.users); // Assuming your API returns a "users" array
      setTotalPages(response.data.pages); // Assuming your API returns "pages" as total number of pages
      setCurrentPage(response.data.page); // Current page from the response
    } catch (error) {
      console.error("Failed to fetch users", error);
    } finally {
      setLoading(false); // Stop loading after the request
    }
  };

  // Fetch users when the component mounts or currentPage changes
  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className={styles.main}>
      <Link className={styles.gobacklink} to="/admin">
        {"<--- Wróć"}
      </Link>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className={styles.userTable}>
            <thead>
              <tr>
                <th>Email</th>
                <th>Subscription</th>
                <th>Zgoda na Newsletter</th>
              </tr>
            </thead>
            <tbody>
              {users.length > 0 ? (
                users.map((user) => (
                  <tr key={user._id}>
                    <td>{user.email}</td>
                    <td
                      style={{
                        color:
                          user.subscription === "premium" ? "green" : "#fff",
                      }}
                    >
                      {user.subscription === "premium" ? "Premium" : "Free"}
                    </td>
                    <td
                      style={{
                        color: user.newsletterConsent ? "#fff" : "red",
                      }}
                    >
                      {user.newsletterConsent ? "Tak" : "Nie"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No users found</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination controls */}
          <div className={styles.pagination}>
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ManageUsers;
