import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import RegulaminSerwisu from "./components/RegulaminSerwisu/RegulaminSerwisu";
import Home from "./components/Home/Home";
import PolitykaPrywatnosci from "./components/PolitykaPrywatnosci/PolitykaPrywatnosci";
import Admin from "./components/Admin/Admin";
import Video from "./components/Video/Video";
import InsertVideo from "./components/InsertVideo/InsertVideo";
import Account from "./components/Account/account";
import ManageVideo from "./components/ManageVideo/ManageVideo";
import EditVideo from "./components/EditVideo/EditVideo";
import ProtectedRoute from "./ProtectedRoute";
import ConfirmationPage from "./components/ConfirmationPage/ConfirmationPage";
import EditAds from "./components/EditAds/EditAds";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import axios from "axios";
import EditLiveHls from "./components/EditLiveHls/EditLiveHls";
import Navbar from "./Navbar";
import { loginUser, toggleIsUserLoading } from "./redux/userSlice";
import Vod from "./components/Vod/vod";
import Footer from "./components/Footer/Footer";
import Offer from "./components/Offer/Offer";
import "./App.css";
import ManageUsers from "./components/ManageUsers/manageUsers";

const cookies = new Cookies();

function App() {
  const [isTVBrowser, setIsTVBrowser] = useState(false);

  const dispatch = useDispatch();

  const token = cookies.get("TOKEN");

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getUser`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (res.data) {
          dispatch(loginUser(res.data));
          dispatch(toggleIsUserLoading());
        }
      } catch (err) {
        console.error(err);
        dispatch(toggleIsUserLoading());
      }
    };

    if (token) {
      dispatch(toggleIsUserLoading());
      getData();
    }
  }, []);

  useEffect(() => {
    // Check if user agent indicates a TV browser
    const userAgent = navigator.userAgent.toLowerCase();
    if (
      userAgent.includes("web0s") || // Example: LG WebOS
      userAgent.includes("smarttv") || // Example: Samsung Smart TV
      userAgent.includes("tv") // Generic TV browser
    ) {
      setIsTVBrowser(true);
    }
  }, []);

  return (
    <div className="App">
      {isTVBrowser ? (
        <div className="tvMessage">
          <h1 className="tvMessage__title">
            Wkrótce pojawi się aplikacja TBN GO
          </h1>
          <p className="tvMessage__text">Dziękuję za Twoją cierpliwość.</p>
        </div>
      ) : (
        <Router>
          <Switch>
            <Route exact path="/user/:userId">
              <Account />
            </Route>
            <Route exact path="/vod/:videoId">
              <Video />
            </Route>
            <Route exact path="/regulamin-serwisu">
              <RegulaminSerwisu />
            </Route>
            <Route exact path="/polityka-prywatnosci">
              <PolitykaPrywatnosci />
            </Route>
            <Route exact path="/payment/:paymentId">
              <ConfirmationPage />
            </Route>
            <Route exact path="/vod">
              <>
                <Navbar />
                <Vod />
                <Footer />
              </>
            </Route>
            <Route exact path="/oferta">
              <Offer />
            </Route>

            <ProtectedRoute exact path="/admin/add-video">
              <InsertVideo />
            </ProtectedRoute>
            <ProtectedRoute exact path="/admin/manage-video/edit/:videoId">
              <EditVideo />
            </ProtectedRoute>
            <ProtectedRoute exact path="/admin/manage-video">
              <ManageVideo />
            </ProtectedRoute>
            <ProtectedRoute exact path="/admin/manage-users">
              <ManageUsers />
            </ProtectedRoute>
            <ProtectedRoute exact path="/admin/edit-ad">
              <EditAds />
            </ProtectedRoute>
            <ProtectedRoute exact path="/admin/edit-live-hls">
              <EditLiveHls />
            </ProtectedRoute>
            <ProtectedRoute exact path="/admin">
              <Admin />
            </ProtectedRoute>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
